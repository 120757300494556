<template>
  <Layout>
    <template v-if="this.$route.name == 'add-survey'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="page">
              <div class="row">
                <b-form-group id="input-group-1" class="col-6">
                  <label for="input-1"
                    >Title <span style="color: red">*</span></label
                  >
                  <b-form-input
                    id="input-1"
                    v-model="form.title"
                    @keyup.prevent="slugify"
                    placeholder="Enter Title"
                    :class="{
                      'is-invalid': submitted && $v.form.title.$error,
                    }"
                  ></b-form-input>
                  <div
                    v-if="submitted && !$v.form.title.required"
                    class="invalid-feedback"
                  >
                    Title is required.
                  </div>
                </b-form-group>
                <b-form-group id="input-group-1" class="col-6">
                  <label for="input-1"
                    >Slug <span style="color: red">*</span></label
                  >
                  <b-form-input
                    id="input-1"
                    v-model="form.slug"
                    placeholder="Enter Slug"
                    :class="{
                      'is-invalid': submitted && $v.form.slug.$error,
                    }"
                  ></b-form-input>
                  <div
                    v-if="submitted && !$v.form.slug.required"
                    class="invalid-feedback"
                  >
                    Slug is required.
                  </div>
                </b-form-group>
                <b-form-group id="input-group-2" class="col-6">
                  <label for="input-1"
                    >Description <span style="color: red">*</span></label
                  >
                  <ckeditor
                    v-model="form.description"
                    :editor="editor"
                    :class="{
                      'is-invalid': submitted && $v.form.description.$error,
                    }"
                  ></ckeditor>
                  <div
                    v-if="submitted && !$v.form.description.required"
                    class="invalid-feedback"
                  >
                    Description is required.
                  </div>
                </b-form-group>
                <b-form-group id="input-group-2" class="col-6">
                  <label for="input-1">HTML Content</label>
                  <b-form-textarea
                    id="input-9"
                    v-model="form.html_content"
                    placeholder="Enter HTML Content"
                    rows="12"
                  >
                  </b-form-textarea>
                </b-form-group>
                <b-form-group
                  id="input-group-3"
                  label="Pass Text"
                  label-for="input-3"
                  class="col-6"
                >
                  <b-form-input
                    id="input-3"
                    v-model="form.pass_text"
                    placeholder="Enter Pass Text"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-3"
                  label="Pass Button Text"
                  label-for="input-3"
                  class="col-6"
                >
                  <b-form-input
                    id="input-3"
                    v-model="form.pass_btn_text"
                    placeholder="Enter Pass Button Text"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-3"
                  label="Fail Text"
                  label-for="input-3"
                  class="col-6"
                >
                  <b-form-input
                    id="input-3"
                    v-model="form.fail_text"
                    placeholder="Enter Fail Text"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-3"
                  label="Fail Button Text"
                  label-for="input-3"
                  class="col-6"
                >
                  <b-form-input
                    id="input-3"
                    v-model="form.fail_btn_text"
                    placeholder="Enter Fail Button Text"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-pi"
                  class="col-6"
                  label="Pass Image [Upload Max File Size : 2MB]"
                  label-for="input-pi"
                >
                  <b-form-file
                    id="input-pi"
                    accept="image/*"
                    placeholder="Choose a file or drop it here..."
                    @change="readFile($event, 'pass_image')"
                    ref="pass_image"
                  ></b-form-file>
                  <template
                    v-if="$route.name == 'edit-survey' && edit.pass_image_url"
                  >
                    <img
                      :src="edit.pass_image_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                  <template v-if="pass_image_url">
                    <img
                      :src="pass_image_url"
                      width="128px"
                      height="128px"
                      ref="pass_image_url"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>
                <b-form-group
                  id="input-group-fi"
                  class="col-6"
                  label="Fail Image [Upload Max File Size : 2MB]"
                  label-for="input-fi"
                >
                  <b-form-file
                    id="input-fi"
                    accept="image/*"
                    placeholder="Choose a file or drop it here..."
                    @change="readFile($event, 'fail_image')"
                    ref="fail_image"
                  ></b-form-file>
                  <template
                    v-if="$route.name == 'edit-survey' && edit.fail_image_url"
                  >
                    <img
                      :src="edit.fail_image_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                  <template v-if="fail_image_url">
                    <img
                      :src="fail_image_url"
                      width="128px"
                      height="128px"
                      ref="fail_image_url"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>
                <b-form-group
                  id="input-group-ti"
                  class="col-6"
                  label="Thumbnail Image [Upload Max File Size : 2MB]"
                  label-for="input-ti"
                >
                  <b-form-file
                    id="input-ti"
                    accept="image/*"
                    placeholder="Choose a file or drop it here..."
                    @change="readFile($event, 'image_name')"
                    ref="image_name"
                  ></b-form-file>
                  <template
                    v-if="$route.name == 'edit-survey' && edit.image_name_url"
                  >
                    <img
                      :src="edit.image_name_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                  <template v-if="image_name_url">
                    <img
                      :src="image_name_url"
                      width="128px"
                      height="128px"
                      ref="image_name_url"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>
                <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                  <label for="form.content_type"
                    >Allowed Members From
                    <span style="color: red">*</span></label
                  >
                  <b-form-select
                    id="content_type"
                    :options="allowedMemberFrom"
                    v-model="form.allowed_members_from"
                    value-field="value"
                    text-field="text"
                    :class="{
                      'is-invalid':
                        submitted && $v.form.allowed_members_from.$error,
                    }"
                  >
                  </b-form-select>
                  <div
                    v-if="submitted && !$v.form.allowed_members_from.required"
                    class="invalid-feedback"
                  >
                    Allowed Members From is required.
                  </div>
                </b-form-group>
                <b-form-group
                  class="col-6"
                  id="input-group-1"
                  label-for="input-csv"
                  v-if="form.allowed_members_from == 'csv'"
                >
                  <div
                    class="d-flex justify-content-between align-items-center mb-2"
                  >
                    <label class="mb-0"
                      >Upload Member CSV File
                      <span style="color: red">*</span></label
                    >
                    <a
                      href="/survey_members.xlsx"
                      download
                      class="btn btn-primary btn-sm"
                    >
                      <i class="fa fa-download mr-1"></i> Download Sample File
                    </a>
                  </div>
                  <b-form-file
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    id="input-csv"
                    placeholder="Choose a file or drop it here..."
                    ref="member_csv_file"
                    @change="readFile($event, 'member_csv_file')"
                  ></b-form-file>
                </b-form-group>
                <b-form-group
                  id="input-group-5"
                  label-for="input-5"
                  class="col-6"
                  v-if="form.is_password == 1"
                >
                  <label
                    >Survey Password <span style="color: red">*</span></label
                  >
                  <b-form-input
                    id="input-5"
                    v-model="form.survey_password"
                    placeholder="Enter Survey Password"
                    :class="{
                      'is-invalid': submitted && $v.form.survey_password.$error,
                    }"
                  >
                  </b-form-input>
                  <div
                    v-if="submitted && !$v.form.survey_password.required"
                    class="invalid-feedback"
                  >
                    Survey Password is required when password checkbox is
                    checked.
                  </div>
                </b-form-group>
                <b-form-group
                  id="input-group-4"
                  label-for="input-4"
                  class="col-6"
                >
                  <label>Google Form Link</label>
                  <b-form-input
                    id="input-4"
                    v-model="form.google_form_link"
                    placeholder="Enter Google Form Link"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-6">
                  <label for="partner_div">Forum </label>
                  <multiselect
                    @search-change="fetchForum"
                    id="partner_div"
                    v-model="form.partner_division_id"
                    :options="forums"
                    :multiple="false"
                    track-by="id"
                    label="title"
                    placeholder="Type here to search"
                  >
                    <span slot="noOptions"> Type here to search </span>
                  </multiselect>
                </b-form-group>
                <b-form-group class="col-6" v-if="form.is_payout">
                  <label>Select Payout Options (at least one):</label>
                  <b-form-checkbox-group
                    v-model="form.payout_options"
                    :options="payoutOptions"
                    inline
                  ></b-form-checkbox-group>
                  <small v-if="this.submitted && !this.$v.form.payout_options.requiredAtLeastOne" class="text-danger">
                    Please select at least one option.
                  </small>
                </b-form-group>
                <!-- <b-form-group class="my-2 col-12">
                  <label for="input-2">Question</label>
                  <div
                    class="row"
                    v-for="(val, index) in form.questions"
                    :key="index"
                  >
                    <div class="col-6">
                      <b-form-group>
                        <multiselect
                          v-model="val.id"
                          @search-change="fetchQuestion"
                          :options="questionName"
                          :multiple="false"
                          track-by="id"
                          label="question"
                          placeholder="Type here to search"
                        >
                          <span slot="noOptions"> Type here to search </span>
                        </multiselect>
                      </b-form-group>
                    </div>
                    <div class="col-3">
                      <b-form-group id="input-group-12">
                        <b-form-checkbox v-model="val.show_answer"
                          >Display Correct Answer</b-form-checkbox
                        >
                        <b-form-checkbox v-model="val.show_answer_details"
                          >Display Answer Details</b-form-checkbox
                        >
                      </b-form-group>
                    </div>
                    <div class="col-3">
                      <b-button
                        variant="success"
                        class="mr-3 btn-sm"
                        :disabled="val.id == 0"
                        @click.prevent="addQuestion(index)"
                        >+</b-button
                      >
                      <b-button
                        variant="danger"
                        class="btn-sm"
                        @click.prevent="removeQuestion(index)"
                        >-</b-button
                      >
                    </div>
                  </div>
                </b-form-group> -->
                <b-form-group id="input-group-26" class="col-12">
                  <div style="display: flex; gap: 10px">
                    <b-form-checkbox v-model="form.is_active"
                      >Is Active</b-form-checkbox
                    >
                    <b-form-checkbox v-model="form.is_password"
                      >Is Password</b-form-checkbox
                    >
                    <b-form-checkbox v-model="form.is_kyc"
                      >Is Kyc</b-form-checkbox
                    >
                    <b-form-checkbox v-model="form.is_payout"
                      >Is Payout</b-form-checkbox
                    >
                    <b-form-checkbox v-model="form.show_on_web"
                      >Show On Web</b-form-checkbox
                    >
                    <b-form-checkbox v-model="form.show_on_app"
                      >Show On App</b-form-checkbox
                    >
                  </div>
                </b-form-group>
              </div>
              <b-button
                type="submit"
                variant="primary"
                class="mr-2"
                @click.prevent="submitData"
                ><span v-if="this.$route.name == 'add-survey'">Save Data</span>
                <span v-else>Update Data</span>
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import pageMixin from "../../../mixins/ModuleJs/survey";
import { required, requiredIf } from "vuelidate/lib/validators";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      editor: ClassicEditor,
      submitted: false,
      title1: "Add Survey",
      title2: "Edit Survey",
      items: [
        {
          text: "Back",
          href: "/survey",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  mixins: [MixinRequest, pageMixin],
  components: {
    ckeditor: CKEditor.component,
    Layout,
    PageHeader,
    Multiselect,
  },
  validations: {
    form: {
      title: { required },
      description: { required },
      allowed_members_from: { required },
      survey_password: {
        required: requiredIf((form) => form.is_password == 1),
      },
      slug: { required },
      is_payout: { required }, // Required for is_payout
      payout_options: {
        requiredAtLeastOne: requiredIf(function () {
          return this.form.is_payout;
        }),
      },
    },
  },
  created() {
    if (this.$route.name == "edit-survey") {
      this.fetchPage(this.$route.params.id);
    }
  },
};
</script>
